.bannerBoxShadow {
  position: relative;
}
.scrolling-text-container {
  width: 100%;
  overflow: hidden; /* This hides the overflowing text */
}

.scrolling-text {
  display: inline-block; /* Display as inline block so it only takes as much width as the text */
  white-space: nowrap; /* Prevent text from wrapping */
  animation: scroll-left 10s linear infinite; /* Use the defined animation for scrolling */
}

@keyframes scroll-left {
  0% {
    transform: translateX(
      100%
    ); /* Start position: completely off the screen to the right */
  }
  100% {
    transform: translateX(
      -100%
    ); /* End position: completely off the screen to the left */
  }
}
// .text-scroll-box {
//   width: 300px; /* Adjust the width as needed */
//   height: 100px; /* Adjust the height as needed */
//   border: 1px solid #ccc;
//   overflow: auto; /* This enables scrolling for overflowed content */
//   white-space: nowrap; /* Prevents line breaks in the text */
// }
.message-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.divYoutube {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}
.imgYoutube {
  background-size: cover;
  -moz-background-size: contain;
  -webkit-background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100% - 16px);
  position: absolute;
  margin: 8px 0;
}
.thumbnail-overlay-image {
  background-size: cover;
  -moz-background-size: contain;
  -webkit-background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100% - 16px);
  position: absolute;
  margin: 8px 0;
}
.ytp-cued-thumbnail-overlay-image {
  background-size: cover;
  -moz-background-size: contain;
  -webkit-background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100% - 16px);
  position: absolute;
  margin: 8px 0;
}

.message {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.numberTextField {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.bannerBoxShadow::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: linear-gradient(135deg, #49e0a8 0%, #49e0a8 100%);
  filter: blur(150px);
  z-index: 1;
  opacity: 0.34;
  transform: translate(-50%, -50%);
}
@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink-animation 2s infinite;
}

@keyframes ZoomIn {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.zoomInOut {
  animation: ZoomIn 2s infinite;
}

.mainContainer1 {
  margin-top: 1rem !important;
  // background: #fff;
  padding: 30px 60px;
  border-radius: 10px;

  h3 {
    color: #222;
    font-size: 22px;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 30px;
  }
}
label {
  font-family: Arial;
}

.dropzonebox {
  // border-radius: 5px;
  margin-top: 12px;
  cursor: pointer;
  @media screen and (max-width: 959px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 767px) {
    padding: 30px 9px 20px 9px;
  }

  @media screen and (max-width: 414px) {
    padding: 20px 9px 20px 9px;
  }
  .lable {
    text-align: center;
  }
  .boxImage {
    position: relative;
    // display: inline-block;
    width: 100%;
    // max-width: 200px;
    // border-radius: 100%;
    background-color: #efefef;

    // max-width: 200px;
    max-height: 200px;
    // min-width: 200px;
    min-height: 200px;
    overflow: hidden;
    // border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    span {
      position: absolute;
      top: 40%;
      right: 43%;
      background-color: #efefef;
      width: 50px;
      height: 50px;
      // border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media screen and (max-width: 414px) {
        width: 37px;
        height: 37px;
        bottom: 17px;
      }
    }

    img {
      max-width: 200px;
      max-height: 200px;
      min-width: 200px;
      min-height: 200px;
      overflow: hidden;
      // border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
    }
  }

  .zebraCrossButton {
    background-image: linear-gradient(
        45deg,
        transparent 49.5%,
        #fff 49.5%,
        #fff 50.5%,
        transparent 50.5%
      ),
      linear-gradient(
        135deg,
        transparent 49.5%,
        #fff 49.5%,
        #fff 50.5%,
        transparent 50.5%
      );
    background-size: 10px 10px;
    background-position: 0 0, 5px 5px;
  }

  .columnShow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .boxImage1 {
    position: relative;
    display: inline-block;
    width: 100%;
    // border-radius: 100%;
    background-color: #efefef;

    // max-width: 200px;
    max-height: 200px;
    // min-width: 200px;
    min-height: 200px;
    overflow: hidden;
    // border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    span {
      position: absolute;
      top: 40%;
      right: 43%;
      background-color: #efefef;
      width: 50px;
      height: 50px;
      z-index: -1;
      // border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      @media screen and (max-width: 414px) {
        width: 37px;
        height: 37px;
        bottom: 17px;
      }
    }

    img {
      max-width: 200px;
      max-height: 200px;
      min-width: 200px;
      min-height: 200px;
      overflow: hidden;
      // border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;

      @media screen and (max-width: 600px) {
        min-width: 200px;
        min-height: 200px;
      }
    }

    @media screen and (max-width: 414px) {
      width: 200px;
      height: 200px;
    }
  }

  button {
    width: 184px;
  }
}
.button.flash {
  animation: flash-animation 1s infinite;
}

// @keyframes flash-animation {
//   0% {
//     opacity: 1;
//   }
//   25% {
//     opacity: 0.6;
//   }
//   50% {
//     opacity: 0.3;
//   }
//   75% {
//     opacity: 0.6;
//   }
//   100% {
//     opacity: 1;
//   }
// }
@keyframes flash-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  25% {
    opacity: 0.8;
    transform: scale(1.02);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.04);
  }
  75% {
    opacity: 0.8;
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.star {
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 46px;
  background-color: #fff;
  clip-path: polygon(
    50% 0%,
    61.8% 20%,
    100% 35.4%,
    80.9% 61.8%,
    90.9% 90.9%,
    64.6% 77.3%,
    35.4% 77.3%,
    9.1% 90.9%,
    19.1% 61.8%,
    0% 35.4%
  );
  // clip-path: polygon(50% 0, 100% 100%, 0 100%);
}

.star1 {
  animation: rotate 3s infinite linear;
}

.star2 {
  animation: rotate 4s infinite linear;
}

.star3 {
  animation: rotate 5s infinite linear;
}

.star4 {
  animation: rotate 6s infinite linear;
}

.star5 {
  animation: rotate 7s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Carousel container */
.carousel-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.carousel-container.active {
  border: #49e0a8; /* Add a property, like border, to highlight the active state */
}

/* Centered slide */
.slick-center div {
  transform: scale(1.05); /* You can adjust the scaling factor */
  transition: transform 0.5s;
}

/* Slide image styling */
.slick-slide img {
  width: 100%;
  height: auto;
  max-height: 200px;
  border: 1px solid #ccc;
  // border-radius: 5px;
}
.slick-active.slick-center {
  transform: scale(1.04);
}
// .slick-active.slick-center > div{padding: 0px 30px;}
.slick-track {
  padding: 25px 0;
}
.slick-slide > div {
  padding: 0 15px;
}
.slick-slider {
  padding: 0 15px;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  border-radius: 50%;
  padding: 17px;
  width: 20px;
  justify-content: center;
  display: flex !important;
  height: 20px;
  border: none;
  align-items: center;
  /* background: rgb(12, 83, 194); */
  background: linear-gradient(
    184deg,
    rgba(12, 83, 194, 0.9836309524) 21%,
    rgba(38, 194, 231, 0.975227591) 100%
  );
  svg {
    font-size: 30px;
    color: #fff;
  }
  &.arrow-next {
    right: -60px;
    cursor: pointer;
    display: flex !important;
  }
  &.arrow-prev {
    left: -60px;
    cursor: pointer;
    display: flex !important;
  }
}
.boxpaddingThumbnail {
  padding: 0 30px;
}

body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  // font-family: 'Saira', sans-serif;
  // background: #000;
}


@font-face {
  font-family: "Arial";
  src: url("./Font/Digital-7Mono.eot");
  src: url("./Font/Digital-7Mono.eot?#iefix") format("embedded-opentype"),
    url("./Font/Digital-7Mono.woff2") format("woff2"),
    url("./Font/Digital-7Mono.woff") format("woff"),
    url("./Font/Digital-7Mono.ttf") format("truetype"),
    url("./Font/Digital-7Mono.svg#Digital-7Mono") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Arial";
  src: url("./Font1/ArialMT.eot");
  src: url("./Font1/ArialMT.eot?#iefix") format("embedded-opentype"),
    url("./Font1/ArialMT.woff2") format("woff2"),
    url("./Font1/ArialMT.woff") format("woff"),
    url("./Font1/ArialMT.ttf") format("truetype"),
    url("./Font1/ArialMT.svg#ArialMT") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
* {
  scrollbar-width: 0px;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-moz-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

.dCol {
  flex-direction: column;
}
.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectImage {
  height: auto !important;
  width: auto;
  max-width: 370px;

}
.custom-arrow svg {
  font-size: 19px !important;
  color: #fff;
  position: absolute !important;
  z-index: 99999 !important;
}
.logoImg {
  // width: 80px;
  position: fixed;
  // top: 10px;
      width: 151px; //128
      top: 18px;
}
.centerLogoBox {
  position: absolute;
  margin: 0 auto;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  // h2: {
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
  @media (max-width: 367px) {
    font-size: 12px !important;
  }
  // }
}
.bgx1 {
  -webkit-animation: rotating 26s linear infinite;
  animation: rotating 26s linear infinite;
}
.mainBox {
  padding: 50px 40px;
  @media (max-width: 767px) {
    padding: 20px 10px 50px;
  }
}
.bgx2 {
  -webkit-animation: rotating2 6s linear infinite;
  animation: rotating2 6s linear infinite;
}

.crypto {
  -webkit-animation: rotating3 3s linear infinite;
  animation: rotating3 3s linear infinite;
}

@-webkit-keyframes rotating3 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.goldshadow {
  width: 800px;
  top: 25%;
  opacity: 0.3;
  filter: blur(130px);
  height: 400px;
  border-radius: 1000px;
  left: 23%;
  z-index: -1;
  position: absolute;
  background-image: radial-gradient(
    22.67% 9.68% at 67.26% 8.27%,
    #ffca64 86%,
    #ffca64 41%
  );
  @media (max-width: 991px) {
    width: 216px;
    height: 216px;
  }
  @media (max-width: 401px) {
    width: 106px;
    height: 106px;
  }
}
@keyframes rotating3 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
.sliderBox {
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: #121212;
  border-top: 3px solid #e35f24;
  text-align: center;
  img {
    width: auto;
    max-width: 165px;
  }
}

.shadeBox {
  right: auto;
  left: 0;
  width: 450px;
  /* bottom: auto; */
  filter: blur(230px);
  height: 550px;
  opacity: 0.55;
  top: 0;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    #ff6600 0%,
    #ff66004f 95.78%
  );
}

.orangeshadeBox {
  right: 0;
  left: 34%;
  width: 400px;
  /* bottom: auto; */
  filter: blur(147px);
  height: 400px;
  opacity: 0.55;
  top: 42%;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    rgba(255, 202, 100, 0.6705882353) 0%,
    rgba(226, 150, 62, 0.81) 95.78%
  );
}

.textCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.GuestUser {
  padding: 8px 24px;
  color: rgb(0, 0, 0);
  border: 1px solid rgba(255, 255, 255);
  font-size: 14px;
  line-height: 21px;
  white-space: pre;
  border-radius: 20px;
  background-color: rgb(10, 10, 10);
  margin: 7px 10px;
  // max-width: 200px;
}

.TopbarButton {
  padding: 8px 24px;
  margin-right: 10px !important;
}
.centerImg {
  position: absolute !important;
  left: 0 !important;
  /* background: gray; */
  width: 38px !important;
  margin-right: 41px !important;
}
.centerbannerimg {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  z-index: 1;
  opacity: 0.5;
  min-width: 800px;
  max-width: 60%;
}
.orangeshadeBoxWon {
  right: 0;
  left: 34%;
  width: 500px;
  /* bottom: auto; */
  filter: blur(227px);
  height: 500px;
  opacity: 0.55;
  top: 21%;
  z-index: 1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(
    36.67% 9.68% at 67.26% 8.27%,
    rgba(255, 202, 100, 0.57) 0%,
    rgba(226, 150, 62, 0.68) 95.78%
  );
}

.dollerImg1 {
  position: absolute;
  right: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}

.dollerImg2 {
  position: absolute;
  left: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}
.dollerImg3 {
  position: absolute;
  left: 45%;
  width: auto !important;
  max-width: 119px !important;
  bottom: -29px;
}
.ticketbannerImg {
  position: absolute;
  top: -122px;
  z-index: -1;
  width: auto !important;
  max-width: 100% !important;
}

.ticketAnimationBox {
  animation: 3s ease-in-out 0s infinite normal none running rotating4;
}

@keyframes rotating4 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  backdrop-filter: blur(4px);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.animationText {
  color: #fff;
  animation: 2s anim-lineUp ease-out infinite;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0.5;
    transform: translateY(80%);
  }
  20% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.3;
    transform: translateY(0%);
  }
  50% {
    opacity: 0.2;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.hedaerTopbar {
  // padding: 20px 0;
}
.containerBox {
  padding: 0px 80px !important;
}
.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #5e4a2e;
  border-radius: 50px;
  width: fit-content;
  padding: 3px;
}

.tabActiveButtons {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);

  border-radius: 50px;
  padding: 11px 35px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
  font-family: "Arial";
  @media (max-width: 768px) {
    padding: 11px 23px;
  }
}

.tabButtons {
  border-radius: 50px;
  padding: 11px 35px;
  white-space: pre;
  cursor: pointer;
  color: rgba(184, 184, 184, 1);
  font-family: "Arial";
  @media (max-width: 768px) {
    padding: 11px 23px;
  }
}

.mainTab1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #ffffff11;
  border-radius: 50px;
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
  padding: 3px;
}
.tabActiveButtons1 {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  padding: 11px 35px;
  color: #e49f46 !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;

  font-family: "Arial";
  @media (max-width: 768px) {
    padding: 11px 23px;
  }
}
.tabButtons1 {
  border-radius: 50px;
  padding: 11px 35px;
  white-space: pre;
  cursor: pointer;
  color: rgba(184, 184, 184, 1);
  font-family: "Arial";
  @media (max-width: 768px) {
    padding: 11px 23px;
  }
}
.wheelSpin11 {
  position: relative;
  border-radius: 20px;
  // overflow: hidden;
  height: 385px;
  // margin-top: 55px;
  @media (max-width: 768px) {
    height: 325px;
    margin-top: 25px;
    margin-bottom: 56px;
  }
  .IndictorBox {
    img {
      position: absolute;
      z-index: 99999;
      left: 50%;
      transform: translateX(-50%);
      top: -4px;
      width: 35px;
      margin-left: -1px;
      @media (max-width: 768px) {
        top: 0px;
      }
    }
  }
  video {
    position: absolute;
    left: 50%;
    top: 0;
    max-width: 100%;
    transform: translateX(-50%);
    @media (max-width: 767px) {
      max-width: unset;
      top: auto;
      bottom: 0;
    }
  }
}
.spinwheel {
  width: 450px;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    // max-width: 100%;
    transform: translate(-50%, -50%);
  }
}
#line1 {
  transition: all 16s ease-out;
}
#line2 {
  transition: all 4s ease-out;
}
.react-tel-input .form-control {
  background: #000000 !important;
  border: 1px solid #5c5c5c !important;
  color: #5c5c5c !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #000 !important;
}
.react-tel-input .selected-flag {
  background: #000 !important;
}
.react-tel-input .flag-dropdown {
  background-color: #000;
  border: 1px solid #5c5c5c !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #000 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

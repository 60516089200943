body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@font-face {
  font-family: "Arial";
  src: url("./Font/Digital-7Mono.eot");
  src: url("./Font/Digital-7Mono.eot?#iefix") format("embedded-opentype"), url("./Font/Digital-7Mono.woff2") format("woff2"), url("./Font/Digital-7Mono.woff") format("woff"), url("./Font/Digital-7Mono.ttf") format("truetype"), url("./Font/Digital-7Mono.svg#Digital-7Mono") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Arial";
  src: url("./Font1/ArialMT.eot");
  src: url("./Font1/ArialMT.eot?#iefix") format("embedded-opentype"), url("./Font1/ArialMT.woff2") format("woff2"), url("./Font1/ArialMT.woff") format("woff"), url("./Font1/ArialMT.ttf") format("truetype"), url("./Font1/ArialMT.svg#ArialMT") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
* {
  scrollbar-width: 0px;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-moz-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

.dCol {
  flex-direction: column;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectImage {
  height: auto !important;
  width: auto;
  max-width: 370px;
}

.custom-arrow svg {
  font-size: 19px !important;
  color: #fff;
  position: absolute !important;
  z-index: 99999 !important;
}

.logoImg {
  position: fixed;
  width: 151px;
  top: 18px;
}

.centerLogoBox {
  position: absolute;
  margin: 0 auto;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .centerLogoBox {
    font-size: 18px !important;
  }
}
@media (max-width: 367px) {
  .centerLogoBox {
    font-size: 12px !important;
  }
}

.bgx1 {
  animation: rotating 26s linear infinite;
}

.mainBox {
  padding: 50px 40px;
}
@media (max-width: 767px) {
  .mainBox {
    padding: 20px 10px 50px;
  }
}

.bgx2 {
  animation: rotating2 6s linear infinite;
}

.crypto {
  animation: rotating3 3s linear infinite;
}
.goldshadow {
  width: 800px;
  top: 25%;
  opacity: 0.3;
  filter: blur(130px);
  height: 400px;
  border-radius: 1000px;
  left: 23%;
  z-index: -1;
  position: absolute;
  background-image: radial-gradient(22.67% 9.68% at 67.26% 8.27%, #ffca64 86%, #ffca64 41%);
}
@media (max-width: 991px) {
  .goldshadow {
    width: 216px;
    height: 216px;
  }
}
@media (max-width: 401px) {
  .goldshadow {
    width: 106px;
    height: 106px;
  }
}

@keyframes rotating3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotating2 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.sliderBox {
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  background-color: #121212;
  border-top: 3px solid #e35f24;
  text-align: center;
}
.sliderBox img {
  width: auto;
  max-width: 165px;
}

.shadeBox {
  right: auto;
  left: 0;
  width: 450px;
  /* bottom: auto; */
  filter: blur(230px);
  height: 550px;
  opacity: 0.55;
  top: 0;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, #ff6600 0%, rgba(255, 102, 0, 0.3098039216) 95.78%);
}

.orangeshadeBox {
  right: 0;
  left: 34%;
  width: 400px;
  /* bottom: auto; */
  filter: blur(147px);
  height: 400px;
  opacity: 0.55;
  top: 42%;
  z-index: -1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgba(255, 202, 100, 0.6705882353) 0%, rgba(226, 150, 62, 0.81) 95.78%);
}

.textCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.GuestUser {
  padding: 8px 24px;
  color: rgb(0, 0, 0);
  border: 1px solid rgb(255, 255, 255);
  font-size: 14px;
  line-height: 21px;
  white-space: pre;
  border-radius: 20px;
  background-color: rgb(10, 10, 10);
  margin: 7px 10px;
}

.TopbarButton {
  padding: 8px 24px;
  margin-right: 10px !important;
}

.centerImg {
  position: absolute !important;
  left: 0 !important;
  /* background: gray; */
  width: 38px !important;
  margin-right: 41px !important;
}

.centerbannerimg {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
  z-index: 1;
  opacity: 0.5;
  min-width: 800px;
  max-width: 60%;
}

.orangeshadeBoxWon {
  right: 0;
  left: 34%;
  width: 500px;
  /* bottom: auto; */
  filter: blur(227px);
  height: 500px;
  opacity: 0.55;
  top: 21%;
  z-index: 1;
  position: absolute;
  transform: rotate(45deg);
  border-radius: 1000px;
  background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgba(255, 202, 100, 0.57) 0%, rgba(226, 150, 62, 0.68) 95.78%);
}

.dollerImg1 {
  position: absolute;
  right: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}

.dollerImg2 {
  position: absolute;
  left: 40px;
  width: auto !important;
  max-width: 150px !important;
  top: 42%;
}

.dollerImg3 {
  position: absolute;
  left: 45%;
  width: auto !important;
  max-width: 119px !important;
  bottom: -29px;
}

.ticketbannerImg {
  position: absolute;
  top: -122px;
  z-index: -1;
  width: auto !important;
  max-width: 100% !important;
}

.ticketAnimationBox {
  animation: 3s ease-in-out 0s infinite normal none running rotating4;
}

@keyframes rotating4 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.animationText {
  color: #fff;
  animation: 2s anim-lineUp ease-out infinite;
}

@keyframes anim-lineUp {
  0% {
    opacity: 0.5;
    transform: translateY(80%);
  }
  20% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.3;
    transform: translateY(0%);
  }
  50% {
    opacity: 0.2;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.containerBox {
  padding: 0px 80px !important;
}

.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid #5e4a2e;
  border-radius: 50px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
}

.tabActiveButtons {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
  border-radius: 50px;
  padding: 11px 35px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
  font-family: "Arial";
}
@media (max-width: 768px) {
  .tabActiveButtons {
    padding: 11px 23px;
  }
}

.tabButtons {
  border-radius: 50px;
  padding: 11px 35px;
  white-space: pre;
  cursor: pointer;
  color: rgb(184, 184, 184);
  font-family: "Arial";
}
@media (max-width: 768px) {
  .tabButtons {
    padding: 11px 23px;
  }
}

.mainTab1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.0666666667);
  border-radius: 50px;
  padding: 3px;
}
@media (max-width: 768px) {
  .mainTab1 {
    overflow-x: scroll;
  }
}

.tabActiveButtons1 {
  background: linear-gradient(164deg, #ffca64 0%, #ca772a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 11px 35px;
  color: #e49f46 !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  font-family: "Arial";
}
@media (max-width: 768px) {
  .tabActiveButtons1 {
    padding: 11px 23px;
  }
}

.tabButtons1 {
  border-radius: 50px;
  padding: 11px 35px;
  white-space: pre;
  cursor: pointer;
  color: rgb(184, 184, 184);
  font-family: "Arial";
}
@media (max-width: 768px) {
  .tabButtons1 {
    padding: 11px 23px;
  }
}

.wheelSpin11 {
  position: relative;
  border-radius: 20px;
  height: 385px;
}
@media (max-width: 768px) {
  .wheelSpin11 {
    height: 325px;
    margin-top: 25px;
    margin-bottom: 56px;
  }
}
.wheelSpin11 .IndictorBox img {
  position: absolute;
  z-index: 99999;
  left: 50%;
  transform: translateX(-50%);
  top: -4px;
  width: 35px;
  margin-left: -1px;
}
@media (max-width: 768px) {
  .wheelSpin11 .IndictorBox img {
    top: 0px;
  }
}
.wheelSpin11 video {
  position: absolute;
  left: 50%;
  top: 0;
  max-width: 100%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .wheelSpin11 video {
    max-width: unset;
    top: auto;
    bottom: 0;
  }
}

.spinwheel {
  width: 450px;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  transform: translate(-50%, -50%);
}
.spinwheel img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#line1 {
  transition: all 16s ease-out;
}

#line2 {
  transition: all 4s ease-out;
}

.react-tel-input .form-control {
  background: #000000 !important;
  border: 1px solid #5c5c5c !important;
  color: #5c5c5c !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #000 !important;
}

.react-tel-input .selected-flag {
  background: #000 !important;
}

.react-tel-input .flag-dropdown {
  background-color: #000;
  border: 1px solid #5c5c5c !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #000 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px rgba(35, 35, 35, 0.1607843137);
}

.bannerBoxShadow {
  position: relative;
}

.scrolling-text-container {
  width: 100%;
  overflow: hidden; /* This hides the overflowing text */
}

.scrolling-text {
  display: inline-block; /* Display as inline block so it only takes as much width as the text */
  white-space: nowrap; /* Prevent text from wrapping */
  animation: scroll-left 10s linear infinite; /* Use the defined animation for scrolling */
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%); /* Start position: completely off the screen to the right */
  }
  100% {
    transform: translateX(-100%); /* End position: completely off the screen to the left */
  }
}
.message-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.divYoutube {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

.imgYoutube {
  background-size: cover;
  -moz-background-size: contain;
  -webkit-background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100% - 16px);
  position: absolute;
  margin: 8px 0;
}

.thumbnail-overlay-image {
  background-size: cover;
  -moz-background-size: contain;
  -webkit-background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100% - 16px);
  position: absolute;
  margin: 8px 0;
}

.ytp-cued-thumbnail-overlay-image {
  background-size: cover;
  -moz-background-size: contain;
  -webkit-background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100% - 16px);
  position: absolute;
  margin: 8px 0;
}

.message {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.numberTextField input::-webkit-outer-spin-button,
.numberTextField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bannerBoxShadow::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: linear-gradient(135deg, #49e0a8 0%, #49e0a8 100%);
  filter: blur(150px);
  z-index: 1;
  opacity: 0.34;
  transform: translate(-50%, -50%);
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.7;
  }
  40% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.5;
  }
  70% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.blink {
  animation: blink-animation 2s infinite;
}

@keyframes ZoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.zoomInOut {
  animation: ZoomIn 2s infinite;
}

.mainContainer1 {
  margin-top: 1rem !important;
  padding: 30px 60px;
  border-radius: 10px;
}
.mainContainer1 h3 {
  color: #222;
  font-size: 22px;
}
@media screen and (max-width: 768px) {
  .mainContainer1 {
    padding: 30px 30px;
  }
}

label {
  font-family: Arial;
}

.dropzonebox {
  margin-top: 12px;
  cursor: pointer;
}
@media screen and (max-width: 959px) {
  .dropzonebox {
    margin-top: 0px;
  }
}
@media screen and (max-width: 767px) {
  .dropzonebox {
    padding: 30px 9px 20px 9px;
  }
}
@media screen and (max-width: 414px) {
  .dropzonebox {
    padding: 20px 9px 20px 9px;
  }
}
.dropzonebox .lable {
  text-align: center;
}
.dropzonebox .boxImage {
  position: relative;
  width: 100%;
  background-color: #efefef;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
.dropzonebox .boxImage span {
  position: absolute;
  top: 40%;
  right: 43%;
  background-color: #efefef;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzonebox .boxImage img {
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
.dropzonebox .zebraCrossButton {
  background-image: linear-gradient(45deg, transparent 49.5%, #fff 49.5%, #fff 50.5%, transparent 50.5%), linear-gradient(135deg, transparent 49.5%, #fff 49.5%, #fff 50.5%, transparent 50.5%);
  background-size: 10px 10px;
  background-position: 0 0, 5px 5px;
}
.dropzonebox .columnShow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dropzonebox .boxImage1 {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: #efefef;
  max-height: 200px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
.dropzonebox .boxImage1 span {
  position: absolute;
  top: 40%;
  right: 43%;
  background-color: #efefef;
  width: 50px;
  height: 50px;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage1 span {
    width: 37px;
    height: 37px;
    bottom: 17px;
  }
}
.dropzonebox .boxImage1 img {
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 600px) {
  .dropzonebox .boxImage1 img {
    min-width: 200px;
    min-height: 200px;
  }
}
@media screen and (max-width: 414px) {
  .dropzonebox .boxImage1 {
    width: 200px;
    height: 200px;
  }
}
.dropzonebox button {
  width: 184px;
}

.button.flash {
  animation: flash-animation 1s infinite;
}

@keyframes flash-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  25% {
    opacity: 0.8;
    transform: scale(1.02);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.04);
  }
  75% {
    opacity: 0.8;
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.star {
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 46px;
  background-color: #fff;
  -webkit-clip-path: polygon(50% 0%, 61.8% 20%, 100% 35.4%, 80.9% 61.8%, 90.9% 90.9%, 64.6% 77.3%, 35.4% 77.3%, 9.1% 90.9%, 19.1% 61.8%, 0% 35.4%);
          clip-path: polygon(50% 0%, 61.8% 20%, 100% 35.4%, 80.9% 61.8%, 90.9% 90.9%, 64.6% 77.3%, 35.4% 77.3%, 9.1% 90.9%, 19.1% 61.8%, 0% 35.4%);
}

.star1 {
  animation: rotate 3s infinite linear;
}

.star2 {
  animation: rotate 4s infinite linear;
}

.star3 {
  animation: rotate 5s infinite linear;
}

.star4 {
  animation: rotate 6s infinite linear;
}

.star5 {
  animation: rotate 7s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Carousel container */
.carousel-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.carousel-container.active {
  border: #49e0a8; /* Add a property, like border, to highlight the active state */
}

/* Centered slide */
.slick-center div {
  transform: scale(1.05); /* You can adjust the scaling factor */
  transition: transform 0.5s;
}

/* Slide image styling */
.slick-slide img {
  width: 100%;
  height: auto;
  max-height: 200px;
  border: 1px solid #ccc;
}

.slick-active.slick-center {
  transform: scale(1.04);
}

.slick-track {
  padding: 25px 0;
}

.slick-slide > div {
  padding: 0 15px;
}

.slick-slider {
  padding: 0 15px;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  border-radius: 50%;
  padding: 17px;
  width: 20px;
  justify-content: center;
  display: flex !important;
  height: 20px;
  border: none;
  align-items: center;
  /* background: rgb(12, 83, 194); */
  background: linear-gradient(184deg, rgba(12, 83, 194, 0.9836309524) 21%, rgba(38, 194, 231, 0.975227591) 100%);
}
.custom-arrow svg {
  font-size: 30px;
  color: #fff;
}
.custom-arrow.arrow-next {
  right: -60px;
  cursor: pointer;
  display: flex !important;
}
.custom-arrow.arrow-prev {
  left: -60px;
  cursor: pointer;
  display: flex !important;
}

.boxpaddingThumbnail {
  padding: 0 30px;
}

@media (max-width: 1225px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
}
@media (max-width: 1024px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .BetAmountBox {
    text-align: center !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
}
@media (max-width: 991px) {
  .containerBox {
    padding: 25px !important;
    margin-top: 40px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 120px;
  }
}
@media (max-width: 767px) {
  .selectquestionBox {
    padding: 10px !important;
  }
  .selectImage {
    height: auto !important;
    width: auto;
    max-width: 370px;
  }
  .custom-arrow svg {
    font-size: 15px;
    color: #fff;
  }
  .custom-arrow.arrow-next {
    right: -50px;
    cursor: pointer;
  }
  .custom-arrow.arrow-prev {
    left: -50px;
    cursor: pointer;
  }
  .slick-active.slick-center {
    transform: scale(1);
  }
  .boxpaddingThumbnail {
    padding: 0 20px;
  }
  .carousel-container {
    padding: 10px 20px 1px;
  }
  .slick-track {
    padding: 15px 0;
  }
  .slick-slide img {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .redtimerTextBox {
    font-size: 14px !important;
    background: rgba(255, 255, 255, 0.2117647059);
    padding: 5px;
    border-radius: 5px;
  }
  .dragText {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .spinnowImg {
    width: 126px !important;
  }
  .spinnowImg1 {
    width: 113px !important;
  }
  .BetAmountBox {
    margin-left: 15px !important;
    margin-top: -8px !important;
    text-align: center !important;
  }
  .hedaerTopbar {
    padding: 5px 0;
  }
  .ticketbannerImg {
    top: -28px;
  }
  .dollerImg3 {
    left: 38%;
    width: 84px !important;
    bottom: -16px;
  }
  .dollerImg2 {
    left: 0;
    width: 117px !important;
    top: 41%;
  }
  .dollerImg1 {
    width: 86px !important;
    top: 36%;
  }
  .TopbarButton {
    padding: 8px 24px;
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 110px;
    z-index: 9999;
    margin-bottom: 20px;
    position: relative;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
}
@media (max-width: 425px) {
  .spinnowImg {
    width: 153px !important;
    margin-top: 0px !important;
    max-width: 100%;
  }
}
@media (max-width: 980px) {
  .mediaQuery {
    display: none;
  }
}
@media (min-width: 980px) {
  .mediaQuery1 {
    display: none;
  }
}/*# sourceMappingURL=main.css.map */
@media (max-width: 1440px) {
}
@media (max-width: 1280px) {
  .TopbarButton {
    // margin: 6px 0 !important;
  }
}
@media (max-width: 1225px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
}
@media (max-width: 1024px) {
  .TopbarButton {
    margin: 6px 0 !important;
  }
  // margin: 6px 0 !important;
  .BetAmountBox {
    text-align: center !important;
  }
  .wheelSpin11 {
    // margin-top: 20px;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 119px;
  }
}
@media (max-width: 991px) {
  .containerBox {
    padding: 25px !important;
    margin-top: 40px !important;
  }
  .TopbarButton {
    margin: 6px 0 !important;
  }
  // .orangeshadeBox {
  //   display: none;
  // }
  .purpleBox {
    display: none;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 120px; //82px
  }
}
@media (max-width: 767px) {
  .selectquestionBox {
    padding: 10px !important;
  }

  .selectImage {
    height: auto !important;
    width: auto;
    max-width: 370px;
  
  }
  .custom-arrow {
    // position: absolute;
    // top: 50%;
    // transform: translateY(-50%);
    // z-index: 999;
    // border-radius: 50%;
    // padding: 10px;
    // border: none;
    // background: rgb(12, 83, 194);
    // background: linear-gradient(
    //   184deg,
    //   rgba(12, 83, 194, 0.9836309523809523) 21%,
    //   rgba(38, 194, 231, 0.9752275910364145) 100%
    // );
    svg {
      font-size: 15px;
      color: #fff;
    }
    &.arrow-next {
      right: -50px;
      cursor: pointer;
    }
    &.arrow-prev {
      left: -50px;
      cursor: pointer;
    }
  }
  .slick-active.slick-center {
    transform: scale(1);
  }
  .boxpaddingThumbnail {
    padding: 0 20px;
  }
  .carousel-container {
    padding: 10px 20px 1px;
  }
  .slick-track {
    padding: 15px 0;
  }
  .slick-slide img {
    width: 100%;
    height: auto;
    // max-height: 150px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .redtimerTextBox {
    font-size: 14px !important;
    background: #ffffff36;
    padding: 5px;
    border-radius: 5px;
  }
  .dragText {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .spinnowImg {
    width: 126px !important;
  }
  .spinnowImg1 {
    width: 113px !important;
  }
  .BetAmountBox {
    margin-left: 15px !important;
    margin-top: -8px !important;
    text-align: center !important;
  }
  .hedaerTopbar {
    padding: 5px 0;
  }
  .ticketbannerImg {
    top: -28px;
  }
  .dollerImg3 {
    left: 38%;
    width: 84px !important;
    bottom: -16px;
  }

  .dollerImg2 {
    left: 0;
    width: 117px !important;
    top: 41%;
  }
  .dollerImg1 {
    width: 86px !important;
    top: 36%;
  }
  .TopbarButton {
    padding: 8px 24px;
    margin: 6px 0 !important;
  }
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 110px; //55px
    z-index: 9999;
    margin-bottom: 20px; // i changed here
    position: relative;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
}

@media (max-width: 425px) {
  .spinnowImg {
    width: 153px !important;
    margin-top: 0px !important;
    max-width: 100%;
  }
}
@media (max-width: 980px) {
  .mediaQuery {
    display: none;
  }
}
@media (min-width: 980px) {
  .mediaQuery1 {
    display: none;
  }
}
